<template>
  <div class="breadcrumbs">
    <div class="container-padding">
      <div class="breadcrumbs__inner">
        <h1 class="breadcrumbs__title">{{ title }}</h1>
        <ul class="breadcrumbs__links">
          <li>
            <router-link :to="{ name: 'home' }" class="breadcrumbs__link">
              <span>Главная</span>
            </router-link>
          </li>
          <li v-for="(link, i) in links" :key="i">
            <router-link :to="link.to" class="breadcrumbs__link">
              <span class="breadcrumbs__link-title">{{ link.title }}</span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BreadCrumbsComponent",
  props: {
    title: {
      type: String,
      required: true,
    },
    links: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="stylus">
.breadcrumbs {
  margin-top 15px
  margin-bottom 55px
  +below(768px) {
    margin 15px 0
  }

  &__inner {
    display flex
    align-items center
    flex-wrap wrap
    justify-content space-between
    gap 15px
  }

  &__title {
    color var(--dark)
    max-width 1225px
  }

  &__links {
    display inline-flex
    gap 10px
  }

  li {
    font-size .875rem
    line-height 22px
    color var(--dark_o5)

    &:not(:last-child) {
      &::after {
        padding-left 10px
        content "/"
      }
    }
  }
}
</style>
