<template>
  <main class="sub-department-page page" v-if="sub_department">
    <BreadCrumbsComponent :title="sub_department.title" />
    <div class="container-padding">
      <div class="sub-department-page__inner">
        <DepartmentCard big :data="sub_department" />
        <EditorJSComponent
          v-if="$options.filters.editor_has_text(sub_department.description)"
          :text="sub_department.description"
          class="sub-department-page__article"
        />
      </div>
    </div>
  </main>
</template>

<script>
import DepartmentCard from "components/DepartmentCard.vue";
import SUB_DEPARTMENT from "gql/pages/SubDepartmentOpenPage.graphql";
import EditorJSComponent from "components/EditorJSComponent.vue";
import BreadCrumbsComponent from "components/BreadcrumbsComponent.vue";

export default {
  name: "MinistryDepartmentsPage",
  async asyncData({ res, apollo, store, route }) {
    if (!route.params.id && res) {
      res.status(404);
    }
    await apollo.defaultClient
      .query({
        query: SUB_DEPARTMENT,
        variables: {
          id: parseInt(route.params.id),
          page: 1,
        },
      })
      .then(({ data }) => {
        if (data) {
          store.dispatch("sub_departments/save", data);
        } else {
          if (res) {
            res.status(404);
          }
        }
      })
      .catch(() => {});
  },
  computed: {
    sub_department() {
      return this.$store.state.sub_departments.sub_departments_item;
    },
  },
  metaInfo() {
    const meta = this.$store.state.sub_departments.sub_departments_item;
    return {
      title: meta?.title,
      // meta: [
      //   {
      //     name: "description",
      //     content: meta?.meta_description,
      //   },
      //   {
      //     name: "keywords",
      //     content: meta?.meta_keywords,
      //   },
      // ],
    };
  },
  components: { EditorJSComponent, DepartmentCard, BreadCrumbsComponent },
};
</script>

<style lang="stylus">
.sub-department-page {
  //padding-top 30px

  &__inner {
    display grid
    grid-gap 50px
    +below(1024px) {
      grid-gap 30px
    }
  }

  &__article {
    font-weight: 500;
    font-size: 1.125em;
    line-height: 28px;
    color: var(--dark);
    margin -22px 0
  }
}
</style>
